<template>
  <div style="text-align:center">
    <b-row class="match-height">
    <b-col lg="6">
    <b-form-group>
      <label for="module">用户池标签:</label>
      <b-button variant="relief-info"> 选择标签 </b-button>&nbsp;
      <b-button variant="relief-info" v-b-modal.modal-login>
        上传Excel
      </b-button>
    </b-form-group>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="导入用户资料"
    >
      <b-form>
        <div class="mt-1">
          Selected file: <strong>{{ file ? JSON.stringify(file) : "" }}</strong>
        </div>
        <b-form-group>
          <label for="module">上传Excel:</label>
          <b-form-file
            accept=".xls, .xlsx"
            @input="uploadChange"
            v-model="file"
          >
            <template slot="file-name" slot-scope="{ names }">
              <b-badge variant="primary">
                {{ names[0] }}
              </b-badge>
            </template>
          </b-form-file>
          <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click="setNow"
          >
            下载模版
          </b-button>
        </b-form-group>
        <b-progress v-model="pvalue" max="100" />
        </br>
        <div align="right">
          <b-button variant="relief-success" @click="submit">
            上传数据
          </b-button>
        </div>
      </b-form>
    </b-modal>
    </b-col>
     <b-col md="6"
      xl="4"  class="mb-1">
    <b-form-group label="执行部门" label-for="dname">
      <b-form-select
       size="sm"
        v-model="roleSelect"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="roleOptions"
        :reduce="(val) => val.value"
        :clearable="false"
        input-id="user-dname"
      />
    </b-form-group>
     </b-col>
      <b-col lg="6">
    <b-form-group>
      <label for="deviceid">每日加粉数量:</label>

      <cleave
       size="sm"
        id="number"
        v-model="number"
        class="form-control"
        :raw="false"
        :options="numbers"
        placeholder="10,000"
      />
    </b-form-group>
      </b-col>
       <b-col lg="6">
    <b-form-group label="加粉时间" label-for="nickname">
      <b-time
        v-model="value"
        v-bind="labels[locale] || {}"
        :locale="locale"
        show-seconds
        @context="onContext"
      >
        <b-button
          size="sm"
          variant="outline-primary"
          class="ml-auto"
          @click="setNow"
        >
          现在时刻
        </b-button>
      </b-time>
      
    </b-form-group>
    </b-col>
       <b-col lg="6">
       <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click="setNow"
          >
            提交
          </b-button>   <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click="setNow"
          >
            重置
          </b-button>
       </b-col>
       </b-row>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTime,
  VBTooltip,
  BAvatar,
  BBadge,
  BFormFile,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BCol,
  BRow,
  BPopover,
  BProgress,
  VBPopover,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Cleave from "vue-cleave-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import * as XLSX from "xlsx"; // 数据导出导入所需要的依赖，
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCol,
    BRow,
    BTime,
    Cleave,
    VBTooltip,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BProgress,
    BFormFile,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BForm,
    BModal,
    VBModal,
    BPopover,
    VBPopover,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    "b-popover": VBPopover,
    Ripple,
  },
  data() {
    return {
      locale: "zh",
      cid: 0,
      did: 0,
      roleSelect: "",
      number: "",
      file: null,
      value: "",

      options: "",
      pvalue: 0,
      phonedata: [],
      accountSelect: "",
      search: "",
      roleOptions: [],
      context: "",
      dir: false,
      numbers: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      labels: {
        zh: {
          labelHours: "小时",
          labelMinutes: "分钟",
          labelSeconds: "秒",
          labelAmpm: "上午下午",
          labelAm: "上午",
          labelPm: "下午",
          labelIncrement: "增量",
          labelDecrement: "减量",
          labelSelected: "选定时间",
          labelNoTimeSelected: "没有选择时间",
        },
      },
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: "light-success",
        0: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;

        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
    const now = new Date();
    this.value = now.toTimeString().slice(0, 8);
  },
  methods: {
    async uploadChange(file) {
      let dataBinary = await this.readFile(file);
      console.log(XLSX);
      let workBook = XLSX.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(workSheet);
      this.phonedata = data;
    },
    readFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        console.log("file", file);
        reader.readAsBinaryString(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
    submit() {
      var num = 1;
      var successnum = 0;
      var failnum = 0;
      this.phonedata.forEach((item, i) => {
        console.log(item["手机号码（必填）"]);
        useJwt
          .addphonenum({
            cid: this.cid,
            phonenumber: item["手机号码（必填）"],
            nickname: item["昵称"],
            tag: item["标签"],
            did: this.did,
            channel: item["渠道"],
          })
          .then((res) => {
            console.log("iiii", num);
            console.log("this.phonedata.length", this.phonedata.length);
            if (res.data.code == "0") {
              successnum = successnum + 1;
            } else {
              failnum = failnum + 1;
            }
            this.pvalue = (num / this.phonedata.length) * 100;
            console.log("this.pvalue", this.pvalue);
            console.log(this.pvalue);
            if (this.pvalue == 100) {
              this.$toast({
                component: ToastificationContent,
                position: "top-center",
                props: {
                  title: `导入完成`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `总${this.phonedata.length}条，上传成功${successnum}，重复数据${failnum}，导入完成！`,
                },
              });
            }
            num = num + 1;
          });
      });
    },
    setNow() {
      const now = new Date();
      this.value = now.toTimeString().slice(0, 8);
    },
    onContext(ctx) {
      this.context = ctx;
    },

    confirmText(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "error",
            title: "删除失败!",
            text: "请联系管理员处理",
            customClass: {
              confirmButton: "btn btn-error",
            },
          });
        }
      });
    },
  },
  mounted() {},
};
</script>
